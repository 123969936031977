@import '../../../../theme/theme';

.profileCardContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  min-height: 300px;
}

.profileBackground {
  background-image: url('../../../../../public/assets/images/profile-bg-image.png');
  width: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 8rem;
  @include mdTabletView {
    flex: 1;
  }
  display: flex;
  justify-content: center;
  align-items: flex-end;
}

.donorCircleMemberBadge {
  width: max-content;
  height: fit-content;
  display: flex;
  gap: 9px;
  margin-bottom: 4.5rem;
  background: rgba(211, 234, 221, 1);
  border-radius: 16px;
  padding: 8px 16px;
  color: $primaryDarkColor;
  font-size: $fontXSmall;
  line-height: 16.34px;
  font-weight: 700;
  svg path {
    fill: $primaryDarkColor;
  }
}

.profilePicture {
  margin-top: -3.5em;
  z-index: 10;
  border-radius: 50%;
  background-color: #fff;
  border: 4.677px solid #fff;
  width: 6rem;
  height: 6rem;

  svg,
  > div {
    width: 100%;
    height: 100%;
  }
}

.profileDetailsContainer {
  width: 100%;
  padding: 0 14px 24px;
}

.profileNameAndDescriptionContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
  gap: 10px;

  h2 {
    font-size: 2rem;
    font-weight: 700;
    line-height: 120%;
    padding-right: 4px;
    text-align: center;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2; /* number of lines to show */
    line-clamp: 2;
    -webkit-box-orient: vertical;
  }

  p {
    font-size: 0.75rem;
    text-align: center;
  }
}

.editProfileIcon {
  background: linear-gradient(
      0deg,
      rgba(33, 150, 83, 0.2) 0%,
      rgba(33, 150, 83, 0.2) 100%
    ),
    #fff;
  width: 2.25rem;
  height: 2.25rem;
  padding: 9px;
  margin-left: auto;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin-top: -2rem;
}

@include xsPhoneView {
  .profileNameAndDescriptionContainer {
    h2 {
      font-size: 1.5rem;
      margin-top: 0;
    }
  }
}
