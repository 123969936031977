.contributionPopup,
.registeredTreePopup {
  > div {
    border-radius: 8px;
  }
  > div:nth-child(1) {
    display: none;
  }
}

.contributionPopup {
  > div {
    padding: 0px;
  }
}

.donationPopupContainer {
  min-width: 235px;
  min-height: 154px;
  border-radius: 8px;
  box-shadow: 2.889px 5.778px 11.556px 0px rgba(0, 0, 0, 0.1);
  .imageContainer {
    height: 94px;
    position: relative;
    &::before {
      content: '';
      position: absolute;
      background: linear-gradient(180deg, rgba(0, 0, 0, 0) 38.29%, #000 100%);
      width: 100%;
      height: 100%;
      display: block;
    }
  }
}

.popupProjctImage {
  border-top-right-radius: 8px;
  border-top-left-radius: 8px;
  border: 1px solid #fff;
  width: 100%;
  height: 94px;
  object-fit: cover;
}

.projectInfoMainContainer {
  display: flex;
  justify-content: space-between;
  gap: 2px;
  height: fit-content;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  padding: 10px;
  flex-direction: column;
}

.trees {
  font-weight: 700;
  font-size: 12px;
}

.countryAndTpo {
  font-size: 10px;
  font-weight: 400;
  margin-bottom: 8px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* number of lines to show */
  line-clamp: 2;
  -webkit-box-orient: vertical;

  .seperator {
    margin: 0px 3px;
    font-weight: 700;
  }
}

.projectImageInfoContainer {
  position: absolute;
  width: max-content;
  left: 10px;
  bottom: 10px;
  color: #fff;
  .projectName {
    font-size: 10px;
    font-weight: 600;
    height: 15px;
  }
}

// styles for registered tree popup
.registeredTreesPopupContainer {
  display: flex;
  padding: 0px 21px;
  flex-direction: column;
  align-items: center;
}

.registeredTreePopup {
  box-shadow: 2.889px 5.778px 11.556px 0px rgba(0, 0, 0, 0.1);
}

.registeredTrees {
  font-size: 14px;
  font-weight: 700;
  margin-top: 5px;
}

.registeredTreeLabel {
  font-size: 10px;
  font-weight: 600;
  line-height: 120%;
}

.registeredTreeDate {
  font-size: 10px;
  font-weight: 600;
}

