@import './../../../../theme/theme';

// white modal
.modal {
  background-color: $backgroundColor;
  border: 0px;
  border-radius: 8px;
  padding: 20px 56px;
  max-width: 90vw;
  min-width: 320px;
  overflow: hidden;
  outline: none;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: $primaryFontColor;
  > h4 {
    font-weight: bold;
    color: $primaryFontColor;
  }
  > div {
    > button {
      margin-top: 6px;
    }
  }
}

.successfullRedeemModal {
  padding: 11px 18px 17px;
  max-width: 80vw;
  min-width: 427px;
}

.errorAndSpinnerDiv {
  justify-content: center;
  margin-top: 10px;
}

.cancelIcon {
  width: 1rem;
  cursor: pointer;
  position: relative;
  bottom: 15px;
  left: 11rem;
  top: -11px;
}

.fixModal {
  max-width: 400px;
  min-height: 260px;
  min-width: 400px;
  align-items: center;
  justify-content: center;
  padding-top: 30px;
  position: relative;
}

.crossButtonDiv {
  position: absolute;
  left: 362px;
  top: 13px;
}

.crossButton {
  width: 15px;
  cursor: pointer;
}

.redeemCode {
  margin-top: 24px;
}

.routeRedeemModal {
  background-color: $backgroundColor;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: 0px;
  outline: none;
  border-radius: 8px;
  position: relative;
  max-width: 400px;
  min-height: 275px;
  min-width: 438px;
  padding: 20px 56px;
  .redeemHeading {
    font-weight: bold;
  }
}

.redeemCodeButtonContainer {
  padding-top: 15px;
}

.crossDiv {
  position: absolute;
  top: 11px;
  left: 400px;
}

.crossWidth {
  width: 17px;
  cursor: pointer;
}

.redeemTitle {
  font-weight: bold;
}

.modalFinal {
  background-color: $backgroundColor;
  border: 0px;
  border-radius: 8px;
  padding: 20px;
  max-width: 80vw;
  min-width: 320px;
  overflow: hidden;
  outline: none;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.inputField {
  margin-top: 10px;
  min-width: 208px;
}

.note {
  padding: 5px;
  color: $primaryFontColor;
}

// Loader for Buttons
.spinner {
  border: 2px solid #f3f3f3;
  border-radius: 50%;
  border-top: 2px solid transparent;
  width: 16px !important;
  height: 16px !important;
  -webkit-animation: spin 1s linear infinite; /* Safari */
  animation: spin 1s linear infinite;
}

@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.formErrors {
  font-family: $primaryFontFamily;
  font-size: $fontSmall;
  color: $dangerColor;
  margin-left: 6px;
  margin-top: 6px;
  max-width: 208px;
  text-align: center;
}

.successMessage {
  font-size: 25px;
  font-weight: bold;
  display: flex;
  flex-direction: column;
  gap: 10px;
  color: $primaryColor;
  > p {
    text-align: center;
  }
}

.plantedBy {
  color: $primaryFontColor;
  text-align: center;
  > span {
    font-style: italic;
  }
  > p {
    font-weight: bold;
  }
}

.header {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.headerCloseIcon {
  height: 32px;
  width: 32px;
  justify-content: center;
  align-items: center;
  display: flex;
}
.headerCloseIcon:hover {
  cursor: pointer;
}
.headerTitle {
  font-family: $primaryFontFamily;
  font-weight: bold;
  font-size: 27px;
  color: $primaryFontColor;
  margin-left: 10px;
}

.thankyouImageContainer {
  display: flex;
  justify-content: center;
}
.thankyouImage {
  background-image: url('/tenants/planet/images/donations/donationCompletion.jpg');
  background-position: center;
  background-size: cover;
  border-radius: 7px;
  margin-top: 20px;
  margin-bottom: 20px;
  width: 280px;
  height: 280px;
  align-self: center;
  position: relative;
  display: flex;
  justify-content: center;
}

.tempThankYouImage {
  background-image: url('/tenants/planet/images/donations/donationCompletion.jpg');
  background-position: center;
  background-size: cover;
  width: 2300px;
  height: 2300px;
  align-self: center;
  position: relative;
  display: flex;
  justify-content: center;
}

.tempDonationCount {
  font-size: 130px;
  font-weight: 600;
  text-align: center;
  position: absolute;
  bottom: 300px;
  padding: 0px 80px;
  color: $light;
  font-variant-ligatures: none;
}

.tempDonationTenant {
  font-size: 75px;
  font-weight: 400;
  font-style: italic;
  text-align: center;
  position: absolute;
  bottom: 60px;
  color: $light;
  font-variant-ligatures: none;
}

.donationCount {
  font-size: $fontLarge;
  font-weight: 600;
  text-align: center;
  position: absolute;
  bottom: 20px;
  color: $light;
  font-variant-ligatures: none;
}

.donationTenant {
  font-size: $fontSmall;
  font-weight: 400;
  font-style: italic;
  text-align: center;
  margin-top: 16px;
  color: $light;
  font-variant-ligatures: none;
}

.pfpLogo {
  position: absolute;
  right: 12px;
  top: 12px;
  height: 36px;
  width: 36px;
  background-color: $backgroundColor;
  border-radius: 36px;
  padding: 4px;
}

.shareRow {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  position: relative;
  top: -30px;
  width: fit-content;
  left: 50%;
  padding: 5px 10px;
  border-radius: 30px;
  transform: translate(-50%, 0%);
  background-color: $backgroundColor;
  box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.12);
  z-index: 2000;
}

.shareIcon {
  padding: 4px;
  // background-color: blue;
  border-radius: 30px;
  margin: 0px 5px;
}

.buttonsContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  z-index: 10;
  :hover {
    cursor: pointer;
  }
}
.downloadButton {
  border-radius: 40px;
  height: 48px;
  padding: 12px 36px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;
  background-color: $primaryColor;
  color: $light;
  font-family: $primaryFontFamily;
  font-weight: 600;
  :hover {
    cursor: pointer;
  }
}

@include smTabletView {
  .speedDialButton:focus {
    outline: none;
  }
  .container {
    width: 412px;
  }
  .thankyouImage {
    width: 372px;
    height: 372px;
  }
}

:export {
  blueishGrey: 'var(--background-color-dark)';
}

.thankyouImageHeader {
  font-size: 20px;
  font-weight: 600;
  text-align: center;
  color: $light;
  font-variant-ligatures: none;
  padding-top: 15%;
  white-space: pre-line;
}

.thankyouImageHeader p h1 {
  font-size: 34px;
  font-weight: bolder;
}

.tempthankyouImageHeader {
  font-size: 100px;
  font-weight: 600;
  text-align: center;
  width: 100%;
  padding-top: 17%;
  color: $light;
  font-variant-ligatures: none;
  white-space: pre-line;
}

.tempthankyouImageHeader p h1 {
  font-size: 150px;
}

.shareOptions {
  > div {
    z-index: 2000;
    top: 0px;
  }
}

.CancelIconContainer {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.CancelButton {
  cursor: pointer;
  position: absolute;
  width: 15px;
  top: -18px;
  left: 262px;
}
