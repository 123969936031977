.privateProfileActions {
    display: flex;
    gap: 8px;
    justify-content: center;
    flex-wrap: wrap;
    
}

.profileCardPublicActions {
    margin-top: auto;
    display: flex;
    flex-direction: column;
    align-items: center;

    .websiteShareActions {
     display: flex;
     margin-top: 8px;
     gap: 8px;
    }
}