@import '../../../../.././theme/theme';

.mapCreditsContainer {
  position: absolute;
  right: 12px;
  bottom: 8px;
  cursor: pointer;
}

.mapCreditsTooltip {
  > svg {
    display: block;
  }
}

.mapCreditsContent {
  padding: 10px;
  width: 330px;
}
.mapSubContainer {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.pointMarkerContainer {
  width: 26px;
  height: 36px;
}

.clusterMarkerContainer {
  width: 35px;
  height: 36px;
}

.registeredTreeClusterMarkerContainer {
  width: 41px;
  height: 36px;
}

//style for contribution statistics
.contributionStatsContainer {
  background: #f0f6f5;
  width: 100%;
  padding-top: 12px;
  padding-bottom: 12px;
  display: flex;
  gap: 16px;
  align-items: center;
  grid-area: 16px;
  justify-content: center;
  @include xsPhoneView {
    margin-bottom: 14px;
  }
}

.contributionStatsSubContainer {
  display: flex;
  height: 100%;
  gap: 10px;
  align-items: center;
}

.statContainer {
  align-items: center;
  width: max-content;
  height: 24px;
  display: flex;
  gap: 8px;
}
.statsIconContainer {
  height: 100%;
  width: 24px;
  background: #6fcf97;
  border-radius: 3px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.stats {
  font-size: 12px;
  font-weight: 400;
  line-height: normal;
}

.donationStatInfoContainer {
  cursor: pointer;
  margin-top: 2px;
}
.donationStatPopup {
  display: inline-flex;
  width: 160px;
  height: 104px;
  padding: 12px 16px;
  font-size: 12px;
  font-weight: 400;
  line-height: normal;
  color: #2f3336;
}
.registeredTreeMarkerContainer {
  width: 26px;
  height: 36px;
}
