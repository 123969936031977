@import '../../../../theme/theme';

.communityContributions {
  display: flex;
  flex-direction: column;
  border-radius: 24px;
  position: relative;

  @include xsPhoneView {
    border-radius: 16px;
  }
}

.header {
  background: linear-gradient(
      0deg,
      rgba(242, 201, 76, 0.15),
      rgba(242, 201, 76, 0.15)
    ),
    linear-gradient(0deg, #ffffff, #ffffff);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 24px;

  .iconContainer {
    display: flex;
    align-items: center;
  }

  @include smTabletView {
    height: 120px;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;
    padding-top: 0;
    padding-left: 36px;
  }

  @include lgLaptopView {
    padding: 0 0 0 36px;
  }
}

.infoIcon {
  position: absolute;
  top: 16px;
  left: 16px;
  cursor: pointer;

  svg {
    overflow: visible;
  }
}

.infoIconPopupContainer {
  font-size: $fontXSmall;
  color: #2f3336;
  border-radius: 12px;
  padding: 12px 16px;
  max-width: 300px;
}

.headerItems {
  display: flex;
  flex-direction: column;
  gap: 10px;

  .headerTitle {
    color: #007a49;
    font-size: $fontLarge;
    font-weight: 700;
  }

  .headerTabs {
    display: none;
  }

  @include smTabletView {
    min-width: 170px;
    padding-bottom: 8px;
    .headerTabs {
      display: flex;
    }
  }

  @include xsPhoneView {
    justify-content: center;
    margin-top: 0px;

    .headerTitle {
      text-align: center;
    }
  }
}

.headerTabs {
  background-color: #f2f2f2;
  display: flex;
  padding: 4px;
  align-items: center;
  justify-content: center;
  width: fit-content;
  border-radius: 8px;

  button {
    color: #333333;
    cursor: pointer;
    margin-left: auto;
    margin-right: auto;
    font-weight: 600;
    font-size: $fontXSmall;
    padding: 6px 8px;
  }

  .selected {
    color: #007a49;
    font-weight: 700;
    border-radius: 6px;
    background: #fff;
  }
}

.mobileHeaderTabContainer {
  display: none;

  @include xsPhoneView {
    display: inline;
    margin: 16px auto 0;
  }
}

.leaderboardList {
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 12px;
  font-size: $fontSmall;
  color: #4f4f4f;
  font-weight: 500;

  .contributionListItem {
    display: flex;
    justify-content: space-between;
    gap: 8px;
    .units {
      font-weight: 700;
      text-wrap: nowrap;
    }
  }

  .horizontalLine {
    border-top: 1px solid #bdbdbd;
    width: 100%;

    &:last-child {
      display: none;
    }
  }
}

.noContributionsContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 20px;
  min-height: 400px;

  .noContributionsMessage {
    font-size: 0.75em;
    color: #4f4f4f;
    font-weight: 700;
  }
}
